window.login = {};
$(document).ready(function () {
    "use strict";
    let options = {
        ui: {
            bootstrap4: true,
            container: "#pwd-container",
            viewports: {
                progress: ".pwstrength_viewport_progress"
            },
            showVerdictsInsideProgressBar: true
        },
        common: {
            debug: true,
            onLoad: function () {
                $('#messages').text('Start typing password');
            }
        }
    };
    //$('#register_password').pwstrength(options);

    /*
    $(".login-page-link").click(function () {
        $("#login, #recover, #register").addClass('d-none');
        $($(this).attr('href')).removeClass('d-none');
    });
    */

    /*
    $("form#login-form").submit(function(e){
        console.log('submitting');
        e.preventDefault();
        $.confirm({
            title: 'Logging in',
            content: 'Logging you in',
            onOpenBefore: function(){
                const self = this;
                this.showLoading();
                login.attemptAuthentication($("#username").val(), $("#password").val())
                    .done((response) => {
                        console.log(response);
                        if(Common.isSuccessfulResponse(response)){
                        */
    /*
     * Authentication was successfull
     */
    /*
                                // Set cookie expiration
                                let expiration_date = new Date();
                                expiration_date.setFullYear(expiration_date.getFullYear() + 1);
                                document.cookie = `token=${response.data.jwt}; path=/; expires=${expiration_date}`;;

                                if(response.data.hasOwnProperty('href_target')){
                                    window.location.href = response.data.href_target;
                                }
                                else{
                                    window.location.href = '/';
                                }
                            }
                            else{
                                Common.handleFailedRequest(response);
                                self.close();
                            }
                        })
                        .fail((response)=>{
                            Common.handleFailedRequest(response);
                        })
                        .always(function(){
                            self.hideLoading();
                        });
                }

            });

        });
    */

    login.attemptAuthentication = (username, password, target_href) => ($.ajax({
        method: 'POST',
        url: '/api/authenticate',
        dataType: 'json',
        data: {
            username: $("#username").val(),
            password: $("#password").val(),
            target_href: $("#target_href").val()
        }
    }));
});